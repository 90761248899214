import React, { useState, useEffect } from 'react';

const StockUpdateNotification = ({ notifications, onDismiss }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        if (notifications.length > 0) {
            setVisible(true);
            return;
        }
    }, [notifications, onDismiss]);

    if (!visible || notifications.length === 0) return null;

    return (
        <div className="" >
            {notifications.map((notification, index) => (
                <div
                    key={index}
                    className="alert alert-warning alert-dismissible fade show"
                    role="alert"
                >
                    <strong>Stock Update: </strong>
                    {notification.itemName} quantity has been adjusted from {notification.oldQty} to {notification.newQty} due to stock limitations.
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => onDismiss(index)}
                        aria-label="Close"
                    >Dismiss</button>
                </div>
            ))}
        </div>
    );
};

export default StockUpdateNotification;