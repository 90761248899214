import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { useBackend } from './BackendContext';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const backend = useBackend();

  const logout = useCallback(() => {
    setIsAuthenticated(false);
    setAccessToken(null);
    setRefreshToken(null);
    setIsAdmin(false);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }, []);

  const isTokenExpired = useCallback((token) => {
    if (!token) return true;
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error('Error decoding token:', error);
      return true;
    }
  }, []);

  const refreshTokens = useCallback(async (tokenToUse) => {
    // Use the passed token or the current state
    const currentRefreshToken = tokenToUse || refreshToken;

    if (!currentRefreshToken) {
      console.error('No refresh token available');
      logout();
      return;
    }

    try {
      const response = await axios.post(`${backend}/api/auth/refresh-token`,
        { refreshToken: currentRefreshToken }
      );
      const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data;

      if (!newAccessToken || !newRefreshToken) {
        throw new Error('Invalid token response');
      }

      setAccessToken(newAccessToken);
      setRefreshToken(newRefreshToken);
      localStorage.setItem('accessToken', newAccessToken);
      localStorage.setItem('refreshToken', newRefreshToken);
      setIsAuthenticated(true);

      const decodedToken = jwtDecode(newAccessToken);
      const cognitoGroups = decodedToken['cognito:groups'] || [];
      setIsAdmin(cognitoGroups.includes('Admin'));

      // Schedule next refresh
      const timeUntilExpiry = (decodedToken.exp * 1000) - Date.now() - 60000;
      if (timeUntilExpiry > 0) {
        setTimeout(() => refreshTokens(newRefreshToken), timeUntilExpiry);
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
      logout();
    }
  }, [backend, logout, refreshToken]); 

  const login = useCallback((tokens) => {
    if (!tokens || !tokens.accessToken || !tokens.refreshToken) {
      console.error('Invalid tokens provided to login');
      return;
    }

    try {
      const decodedToken = jwtDecode(tokens.accessToken);
      if (isTokenExpired(tokens.accessToken)) {
        throw new Error('Access token is expired');
      }

      setIsAuthenticated(true);
      setAccessToken(tokens.accessToken);
      setRefreshToken(tokens.refreshToken);
      localStorage.setItem('accessToken', tokens.accessToken);
      localStorage.setItem('refreshToken', tokens.refreshToken);

      const cognitoGroups = decodedToken['cognito:groups'] || [];
      setIsAdmin(cognitoGroups.includes('Admin'));

      // Schedule token refresh
      const timeUntilExpiry = (decodedToken.exp * 1000) - Date.now() - 60000;
      if (timeUntilExpiry > 0) {
        setTimeout(() => refreshTokens(tokens.refreshToken), timeUntilExpiry);
      }
    } catch (error) {
      console.error('Error in login:', error);
      logout();
    }
  }, [logout, refreshTokens, isTokenExpired]);

  useEffect(() => {
    const initializeAuth = async () => {
      const storedAccessToken = localStorage.getItem('accessToken');
      const storedRefreshToken = localStorage.getItem('refreshToken');

      if (!storedAccessToken || !storedRefreshToken) {
        setLoading(false);
        return;
      }

      if (isTokenExpired(storedAccessToken)) {
        // If access token is expired, try to refresh using stored refresh token
        await refreshTokens(storedRefreshToken);
      } else {
        // If access token is still valid, use it
        login({ accessToken: storedAccessToken, refreshToken: storedRefreshToken });
      }
      setLoading(false);
    };

    initializeAuth();
  }, [login, refreshTokens, isTokenExpired]);

  const value = {
    isAuthenticated,
    accessToken,
    isAdmin,
    login,
    logout,
    refreshTokens,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;