import React, { useRef, useState, useEffect } from 'react';
import { useCart } from './CartContext.js';
import { useAuth } from './AuthContext.js';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useBackend } from './BackendContext.js';
import { ReactComponent as LEDIcon } from '../led-icon.svg';
import { ReactComponent as LEDIconHover } from '../led-icon-hover.svg';
import ReactGA from 'react-ga4';

const Navbar = () => {
    const dropdownRef = useRef(null);
    const navbarRef = useRef(null);
    const { state } = useCart();
    const { isAuthenticated, logout, isAdmin } = useAuth();
    const [products, setProducts] = useState([]);
    const backend = useBackend();
    const [isOpen, setIsOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${backend}/api/products`);
                setProducts(response.data);
            } catch (error) {
                ReactGA.event({ category: 'Fetch Products', action: 'exception' });
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, [backend]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navbarRef.current && !navbarRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const totalItems = state.cart.reduce((total, item) => total + item.quantity, 0);

    const closeDropdown = () => {
        if (dropdownRef.current && dropdownRef.current.classList.contains('show')) {
            dropdownRef.current.classList.remove('show');
        }
    };

    const toggleDropdown = (event) => {
        event.preventDefault();
        if (dropdownRef.current) {
            dropdownRef.current.classList.toggle('show');
        }
    };

    const toggleMobileMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMobileMenu = () => {
        setIsOpen(false);
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const renderProductsMenu = () => {
        if (products.length === 1) {
            return (
                <li className="nav-item">
                    <Link
                        className="nav-link"
                        to={`/products/${products[0].name}`}
                        onClick={closeMobileMenu}
                    >
                        {products[0].name}
                    </Link>
                </li>
            );
        } else if (products.length > 1) {
            return (
                <li className="nav-item dropdown">
                    <div
                        className="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={toggleDropdown}
                    >
                        Products
                    </div>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown" ref={dropdownRef}>
                        {products.map(product => (
                            <Link
                                key={product.id}
                                className="dropdown-item"
                                to={`/products/${product.name}`}
                                href={`#/products/${product.name}`}
                                onClick={() => { closeDropdown(); closeMobileMenu(); }}
                            >
                                {product.name}
                            </Link>
                        ))}
                    </div>
                </li>
            );
        }
        return null;
    };

    return (
        <nav ref={navbarRef} className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
            <Link
                className="navbar-brand d-flex align-items-center"
                to="/"
                href={`#`}
                onClick={closeMobileMenu}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {isHovered ? (
                    <LEDIconHover style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                ) : (
                    <LEDIcon style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                )}
                <span>LED Reminder</span>
            </Link>
            <button className="navbar-toggler" type="button" onClick={toggleMobileMenu} aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
                <ul className="navbar-nav ml-auto">
                    {renderProductsMenu()}
                    <li className="nav-item">
                        <div className="cart-count-container">
                            <Link
                                className="nav-link"
                                to="/cart"
                                href={`#/cart`}
                                onClick={closeMobileMenu}
                            >
                                View Cart
                            </Link>
                            {totalItems > 0 && (
                                <span className="cart-count">{totalItems}</span>
                            )}
                        </div>
                    </li>
                    <li className="nav-item">
                        <Link
                            className="nav-link"
                            to="/about"
                            href={`#/about`}
                            onClick={closeMobileMenu}
                        >
                            About
                        </Link>
                    </li>
                    {!isAuthenticated ? (
                        <>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="/login"
                                    href={`#/login`}
                                    onClick={closeMobileMenu}
                                >
                                    Login
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="/register"
                                    href={`#/register`}
                                    onClick={closeMobileMenu}
                                >
                                    Register
                                </Link>
                            </li>
                        </>
                    ) : (
                        <>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="/profile"
                                    href={`#/profile`}
                                    onClick={closeMobileMenu}
                                >
                                    My Account
                                </Link>
                            </li>
                            {isAdmin && (
                                <li className="nav-item">
                                    <Link
                                        className="nav-link"
                                        to="/admin"
                                        href={`#/admin`}
                                        onClick={closeMobileMenu}
                                    >
                                        Admin Panel
                                    </Link>
                                </li>
                            )}
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="/"
                                    href={`#`}
                                    onClick={() => { logout(); closeMobileMenu(); }}
                                >
                                    Logout
                                </Link>
                            </li>
                        </>
                    )}
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;