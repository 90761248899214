import React, { createContext, useReducer, useEffect, useContext } from 'react';

const CartContext = createContext();

const initialState = {
  cart: JSON.parse(localStorage.getItem('cart')) || []
};

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      const existingItemIndex = state.cart.findIndex(item => item.id === action.payload.id);
      if (existingItemIndex >= 0) {
        const updatedCart = state.cart.map((item, index) =>
          index === existingItemIndex
            ? {
              ...item,
              quantity: Math.min(item.quantity + action.payload.quantity, action.payload.stock)
            }
            : item
        );
        return { ...state, cart: updatedCart };
      } else {
        const newItem = {
          ...action.payload,
          quantity: Math.min(action.payload.quantity, action.payload.stock)
        };
        return { ...state, cart: [...state.cart, newItem] };
      }
    case 'REMOVE_FROM_CART':
      return { ...state, cart: state.cart.filter(item => item.id !== action.payload.id) };
    case 'UPDATE_QUANTITY':
      const updatedCart = state.cart.map(item =>
        item.id === action.payload.id
          ? { ...item, quantity: Math.min(action.payload.quantity, item.stock) }
          : item
      );
      return { ...state, cart: updatedCart };
    case 'UPDATE_STOCK':
      return {
        ...state,
        cart: state.cart.map(item => {
          if (item.id === action.payload.id) {
            const newQuantity = Math.min(item.quantity, action.payload.stock);
              return {
              ...item,
              stock: action.payload.stock,
              quantity: newQuantity,
              quantityChanged: newQuantity !== item.quantity ? {
                oldQty: item.quantity,
                newQty: newQuantity
              } : null
            };
          }
          return item;
        })
      };
    case 'CLEAR_CART':
      return { ...state, cart: [] };
    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(state.cart));
  }, [state.cart]);

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);