import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useCart } from './CartContext';
import { useAuth } from './AuthContext';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import '../Form.css';
import ReactGA from 'react-ga4';
import axios from 'axios';
import { useBackend } from './BackendContext';
import SEO from './Seo.js';
import { SEO_DATA } from './SeoData.js';
import StockUpdateNotification from './StockUpdateNotification';

// Cache management
const CACHE_DURATION = 60000; // 1 minute in milliseconds
let lastFetchTimestamp = 0;
let fetchPromise = null;

const Cart = () => {
  const { state, dispatch } = useCart();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [showAuthMessage, setShowAuthMessage] = useState(false);
  const backend = useBackend();
  const [isStockLoading, setIsStockLoading] = useState(false);
  const [stockNotifications, setStockNotifications] = useState([]);
  const initialLoadRef = useRef(false);

  // Check if cart has valid quantities
  const hasValidQuantities = () => {
    return state.cart.every(item => item.quantity > 0);
  };

  useEffect(() => {
    const notifications = state.cart
      .filter(item => item.quantityChanged)
      .map(item => ({
        itemName: item.name,
        oldQty: item.quantityChanged.oldQty,
        newQty: item.quantityChanged.newQty
      }));

    if (notifications.length > 0) {
      setStockNotifications(prev => [...prev, ...notifications]);
      ReactGA.event({
        category: 'Cart',
        action: 'stock_quantity_adjusted',
        label: notifications.map(n => n.itemName).join(', ')
      });
    }
  }, [state.cart]);

  const handleDismissNotification = (index) => {
    setStockNotifications(prev =>
      index !== undefined
        ? prev.filter((_, i) => i !== index)
        : []
    );
  };

  // Function to fetch stock with caching
  const fetchStockWithCache = useCallback(async () => {
    const now = Date.now();

    // If there's an ongoing fetch, return that promise
    if (fetchPromise) {
      return fetchPromise;
    }

    // If cache is still valid, skip the fetch
    if (now - lastFetchTimestamp < CACHE_DURATION) {
      return Promise.resolve();
    }

    // Start new fetch
    try {
      setIsStockLoading(true);
      fetchPromise = axios.post(`${backend}/api/products/batch`, {
        productNames: state.cart.map(item => item.name)
      });

      const response = await fetchPromise;
      lastFetchTimestamp = now;

      // Update stock for each item
      response.data.products.forEach(product => {
        dispatch({
          type: 'UPDATE_STOCK',
          payload: {
            id: product.id,
            stock: product.available_stock
          }
        });
      });

      if (response.data.metadata?.notFound?.length > 0) {
        console.warn('Some products were not found:', response.data.metadata.notFound);
      }
    } catch (error) {
      console.error('Error fetching latest stock:', error);
    } finally {
      setIsStockLoading(false);
      fetchPromise = null;
    }
  }, [backend, dispatch, state.cart]);

  // Page view tracking
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  // Stock fetching effect
  useEffect(() => {
    if (state.cart.length > 0 && !initialLoadRef.current) {
      initialLoadRef.current = true;
      fetchStockWithCache();

      // Set up interval for periodic updates
      const intervalId = setInterval(fetchStockWithCache, CACHE_DURATION);

      // Cleanup
      return () => {
        clearInterval(intervalId);
        fetchPromise = null;
        initialLoadRef.current = false;
      };
    }
  }, [state.cart.length, fetchStockWithCache]);

  const handleRemove = (item) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: item });
    ReactGA.event({ category: 'Cart', action: 'remove_from_cart', label: item.id });
  };

  const handleQuantityChange = (item, quantity) => {
    if (quantity > item.stock) {
      quantity = item.stock;
    }
    dispatch({ type: 'UPDATE_QUANTITY', payload: { id: item.id, quantity } });
    ReactGA.event({ category: 'Cart', action: 'update_cart_quantity', label: item.id });

    // Only fetch if cache is expired
    const now = Date.now();
    if (now - lastFetchTimestamp >= CACHE_DURATION) {
      fetchStockWithCache();
    }
  };

  const handleCheckout = () => {
    if (isAuthenticated) {
      navigate('/checkout');
      ReactGA.event({ category: 'Cart', action: 'begin_checkout' });
    } else {
      setShowAuthMessage(true);
      setTimeout(() => setShowAuthMessage(false), 5000);
    }
  };

  const calculateTotal = () => {
    return state.cart.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  return (
    <>
      <SEO {...SEO_DATA.cart} />
      <div className="cart-container">
        <h2>Shopping Cart</h2>
        <StockUpdateNotification
          notifications={stockNotifications}
          onDismiss={handleDismissNotification}
        />
        {state.cart.length === 0 ? (
          <p>Your cart is empty</p>
        ) : (
          <div className="list-group">
            {state.cart.map((item, index) => (
              <div key={index} className="list-group-item cart-item">
                <div className="row align-items-center text-center ">
                  <div className="col-4 col-md-3 text-left pl-3 pl-lg-4">{item.name}</div>
                  <div className="col-2 col-md-4 col-lg-4 text-sm-left text-right">${item.price}</div>
                  <div className="col-2 col-md-2 text-right">
                    <input
                      type="number"
                      value={item.quantity}
                      onChange={(e) => handleQuantityChange(item, Number(e.target.value))}
                      min="1"
                      max={item.stock}
                      className="quantity-input form-control"
                      disabled={isStockLoading}
                    />
                  </div>
                  <div className="col-2 col-md-2 text-md-left">${(item.price * item.quantity).toFixed(2)}</div>
                  <div className="col-2 col-md-1 text-right pl-0">
                    <button
                      className="btn btn-danger btn-sm removeButton"
                      onClick={() => handleRemove(item)}
                      disabled={isStockLoading}
                    >
                      X
                    </button>
                  </div>
                </div>
                {item.quantity === item.stock && item.quantity > 0 && (
                  <div className="row">
                    <div className="col-12 text-danger">
                      Maximum available quantity reached
                    </div>
                  </div>
                )}
                {item.stock === 0 && (
                  <div className="row">
                    <div className="col-12 text-danger">
                      Item is out of stock, remove from cart to proceed
                    </div>
                  </div>
                )}
                {item.quantity <= 0 && item.stock > 0 && (
                  <div className="row">
                    <div className="col-12 text-danger">
                      Please set quantity greater than 0 or remove item from cart
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        {state.cart.length > 0 && (
          <div>
            <div className="total mt-3 text-center">
              <strong>Total: ${calculateTotal()}</strong>
            </div>
            <div className="d-flex justify-content-center">
              <button
                onClick={handleCheckout}
                className="btn btn-primary mt-3"
                disabled={isStockLoading || !hasValidQuantities()}
              >
                Proceed to Checkout
              </button>
            </div>
            {!hasValidQuantities() && (
              <div className="alert alert-warning mt-3 text-center" role="alert">
                Please ensure all items in your cart have a quantity greater than 0 before proceeding to checkout.
              </div>
            )}
            {showAuthMessage && (
              <div className="alert alert-warning mt-3 text-center" role="alert">
                You must be logged in to proceed to checkout. Please{' '}
                <Link
                  to="/login"
                  className="alert-link"
                  state={{ from: location }}
                >
                  log in
                </Link>{' '}
                or{' '}
                <Link
                  to="/register"
                  className="alert-link"
                  state={{ from: location }}
                >
                  register
                </Link>.
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Cart;