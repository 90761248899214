import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import SEO from './Seo.js';

const Home = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  return (
    <>
      <SEO
        title="Home"
        description="LED Reminder is a simple, effective device designed to help you remember important tasks and events through visual cues. With five independently controllable LEDs, it's the perfect tool to enhance your daily routine."
        keywords="LED reminder, visual reminder, task management, LED notification, smart reminder, visual cue system"
        canonical="/"
      />
      <div className="container py-4">
        <div className="jumbotron text-center bg-white rounded-3 mb-1 py-3">
          <h1 className="display-4">LED Reminder</h1>
          <p className="lead">Your Custom Visual Reminder System</p>
        </div>

        <div className="text-center mb-2 col-8 col-md-4 mx-auto">
          <img src="/LEDReminder.png" alt="LED Reminder Device" className="img-fluid rounded" />
        </div>

        <section className="mb-5">
          <h2>What is LED Reminder?</h2>
          <p className='lead p-3'>LED Reminder is a simple, effective device designed to help you remember important tasks and events through visual cues alone. With five independently controllable LEDs and corresponding buttons, it's a useful tool to enhance your daily routine and help you stay on track.</p>
          <h3 className='text-center'>Why not just use an alarm on my phone?</h3>
          <p>While smartphone alarms are common, LED Reminder offers a different approach:</p>
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <ul>
                <li><strong>Subtle reminders: </strong>LED Reminder provides visual cues that don't disrupt your focus or disturb others around you.</li>
                <li><strong>Always visible: </strong>Your reminders are in sight without needing to check your phone.</li>
                <li><strong>Reduced distractions: </strong>By moving reminders off your phone, you're less likely to get sidetracked by other apps and notifications.</li>
                <li><strong>Multiple reminders: </strong>With five independent LEDs, you can manage several tasks or schedules at once.</li>
              </ul>
            </div>
          </div>
        </section>

        <div className="row mb-5">
          <div className="col-md-4 mb-3">
            <div className="card h-100">
              <div className="card-body">
                <h3 className="card-title text-center">Customizable</h3>
                <p className="card-text">5 LEDs with independent settings for various reminders</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="card h-100">
              <div className="card-body">
                <h3 className="card-title text-center">Flexible Modes</h3>
                <p className="card-text">Manual, Offset, Set Time, and Fixed Interval modes</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="card h-100">
              <div className="card-body">
                <h3 className="card-title text-center">Easy Setup</h3>
                <p className="card-text">Simple configuration via text file, no downloads or installs required</p>
              </div>
            </div>
          </div>
        </div>

        <section className="bg-light p-4 rounded mb-5">
          <h2>Use Cases</h2>
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <ul>
                <li><strong>Medication reminders: </strong>Set specific times for each dose</li>
                <li><strong>Plant watering schedule: </strong>Different LEDs for various plants</li>
                <li><strong>Work breaks: </strong>Remind yourself to take regular breaks</li>
                <li><strong>Task rotation: </strong>Visual cues for switching between tasks</li>
                <li><strong>Habit formation: </strong>Daily reminders for new habits</li>
              </ul>
            </div>
          </div>
        </section>

        <div className="text-start">
          <h2>How It Works</h2>
          <p>Setting up and using your LED Reminder is straightforward:</p>
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <ol>
                <li>
                  <strong>Plug in: </strong>When you first receive your LED Reminder, simply plug it into your computer using the provided USB cable. Your computer will recognize it as a standard USB drive - just like a regular thumb drive.
                </li>
                <li>
                  <strong>Easy Configuration: </strong>On this USB drive, you'll find a single file named <code>settings.txt</code>. This is your control center for the device. Open it with any text editor (like Notepad) to customize your reminder settings.
                </li>
                <li>
                  <strong>Simple Settings: </strong>The <code>settings.txt</code> file uses plain English instructions. You can easily set:
                  <ul className="my-2 col-md-9 offset-md-1">
                    <li>WiFi details - So your LED Reminder can keep accurate time</li>
                    <li>Time zone - To ensure your reminders sync with your local time</li>
                    <li>LED behaviors - Choose how each light works (e.g., blink, stay on, or fade)</li>
                    <li>Reminder schedules - Set specific times or intervals for each LED</li>
                  </ul>
                </li>
                <li>
                  <strong>Save and Go: </strong>After editing the settings, just save the file and safely eject the USB drive from your computer. Your LED Reminder is now ready to use!
                </li>
                <li>
                  <strong>Plug It In and Forget It: </strong>Once configured, plug your LED Reminder into any USB power source (like a phone charger or power bank). It will automatically connect to WiFi and start working based on your settings.
                </li>
                <li>
                  <strong>Easy Updates: </strong>Need to change something? Just plug it back into your computer and edit the settings file again. It's that simple!
                </li>
              </ol>
            </div>
          </div>
          <p>Remember, there's no special software to install or complex menus to navigate. Your LED Reminder is designed to be as user-friendly as possible, bringing you helpful visual reminders without any technical hassle.</p>
        </div>

        <div className="bg-primary text-white p-5 rounded text-center">
          <h2>Ready to Never Forget Again?</h2>
          <p>Get your LED Reminder today and take control of your daily reminders!</p>
          <Link
            to="/products/LED%20Reminder"
            href={`#/products/LED%20Reminder`}
            className="btn btn-light btn-lg"
          >
           Shop Now
          </Link>
        </div>
      </div>
    </>
  );
};

export default Home;