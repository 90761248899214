import React from 'react';
import SEO from './Seo';

const AboutPage = () => {
    return (
        <>
            <SEO
                title="About LED Reminder"
                description="Discover the story behind LED Reminder, a visual reminder system born from personal experience and dedicated to making daily tasks more manageable."
                keywords="LED reminder, visual reminder, medication reminder, personal story, about us"
                canonical="/about"
            />
            <div className="container">
                <div className="col-12 col-md-10 offset-md-1">
                    <div className="row">
                        <div className="col">
                            <div className="mb-4">
                                <h1 className="text-center mb-4">Where did the Idea come from?</h1>
                                <p className="text-left mb-4">
                                    The story of LED Reminder begins with a simple daily challenge that many of us face - remembering to take medication. I found myself constantly questioning whether I had taken my morning pills. There were days when I would start feeling ill by late afternoon, realizing I had missed my dose. Other times, uncertain if I had taken them, I would double up rather than risk that illness again. Like many, I tried using phone alarms, but found myself habitually swiping away these interruptions while focused on other tasks, ultimately defeating their purpose.
                                </p>
                                <p className="text-left mb-4">
                                    This frustration led to the creation of the first LED Reminder prototype for my personal use. The concept was beautifully simple: a light in the corner of my desk that I could check at a glance. No intrusive alarms, no interruptions to my workflow - just a visual cue that was always there when I needed it. Press a button when taking medication, and the light goes off. This simple solution made a noticeable difference in my daily routine.
                                </p>
                                <p className="text-left mb-4">
                                    However, the true significance of this device became apparent during a more challenging time in my life. In the later stages of my mother's cancer journey, we faced the complex task of managing her pain patches, which needed changing every three days. With multiple caregivers involved, keeping track of the schedule became increasingly difficult. The timing was crucial - proper pain management was fundamental to my mother's quality of life. I adapted the original concept to create a version specifically for tracking her patch changes, giving our family one less thing to worry about during an already overwhelming time.
                                </p>
                                <p className="text-left mb-4">
                                    The development of LED Reminder and this website has become more than just a practical project - it's become a way to channel my grief into something meaningful. While working on it, I'm constantly reminded of my mother and our family's journey together. Looking back, I know there was only so much I could do to help her, and this device was just one small tool among many that we used. But sometimes it's these small things, these tiny improvements in daily life, that can make a difference when everything else feels overwhelming.
                                </p>
                                <p className="text-left mb-5">
                                    What started as a personal solution has grown into something I hope can help others facing similar challenges. It's not about replacing existing reminder systems or revolutionizing how we remember things - it's about offering another option, one that worked for me and my family.
                                </p>
                            </div>

                            <div className="mt-5 pt-3 border-top text-center">
                                <h2 className="h4 mb-4">In Memory of Kathy Demera</h2>
                                <div className="row justify-content-center">
                                    <div className="col-md-6">
                                        <div className="mb-4">
                                            <img
                                                src="/mom.jpg"
                                                alt="Kathy Demera"
                                                className="img-fluid rounded shadow"
                                                style={{ maxWidth: '300px' }}
                                            />
                                        </div>
                                        <p className="text-muted mb-4">
                                            This project is dedicated to my mother, whose experience inspired its creation.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutPage;